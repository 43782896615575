<template>
  <div class="app-container">
    <div class="app-header-wrap">
      <div class="app-header">
        <div class="header-left">
          <logo></logo>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelectMenu"
            background-color="#409EFF"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item index="home">工作台</el-menu-item>
            <el-menu-item index="enterprise">企业管理</el-menu-item>
            <el-menu-item index="school">学校管理</el-menu-item>
            <el-submenu index="system">
              <template slot="title">系统管理</template>
              <el-menu-item index="2-1">用户管理</el-menu-item>
              <el-menu-item index="2-2">字典管理</el-menu-item>
              <el-menu-item index="2-3">角色管理</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="header-right">
          管理中心
        </div>
      </div>
    </div>
    <div class="app-content-wrap">
      <router-view />
    </div> 
  </div>
</template>

<script>
import Logo from "@/components/Common/Logo";
export default {
  components: { Logo },
  name: "AdminLayout",
  data() {
    return {
      activeIndex: "home",
      isLogin: false
    };
  },
  methods: {
    handleSelectMenu(key, keyPath) {
      console.log(key, keyPath);
      if (key == "home") {
        this.$router.push(`/admin`);
      } else {
        this.$router.push(`/admin/${key}`);
      }
    },
    handleLogin() {
      this.isLogin = true;
    },
    handleResume() {
      this.$router.pusth("/");
    }
  }
};
</script>

<style lang="scss" scoped></style>
